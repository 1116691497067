import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Svg from '../../assets/art2_1a.svg';

const Slide = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`2_1a`}>
			<div className={s.slidecontainer}>
				<div 
					className={s.txt} 
					data-id={`2_1a0`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>
				<div 
					className={s.txt} 
					data-id={`2_1a1`} 
					dangerouslySetInnerHTML={{__html: p.data[1] }} 
				/>
			</div>

			<div className={s.art2_1a}>
				<div className={s.art}>
					<Svg />
				</div>
			</div>


		</section>
)}

export default Slide