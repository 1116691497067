import React, { useEffect, useState } from 'react'
import * as s from "./slide.module.css"

import Lift from "../../assets/lift.svg"

const Slide = (p) => {

	const [active, setActive] = useState(-1);

	useEffect(() => {

	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`7_1`}>
			<div className={s.slidecontainer} data-id={`7_1`} id={"slidecontainer71"}>
				


				<div className={s.liftflex} >
					<div id={`graph71`} className={s.graph71}>
						<Lift />
					</div>
					<div className={s.liftlist}>
					{
						p.data[1].map((d,i)=>(
							<div
								key={i}
								className={s.liftitem + ((i===active)?(` ` + s.liftitemactive):``) + ` liftitem`}
	
								onClick={()=>{ setActive((i!==active)?i:-1) }}

							>
								<div className={s.liftitemline1}>
									<span 
										className={s.litsitemh1}
										dangerouslySetInnerHTML={{__html: d[0] }}
									/>
									
									<svg 
										xmlns="http://www.w3.org/2000/svg" 
										width="22" 
										height="22" 
										viewBox="0 0 22 22"
										className={s.cross} 
									>
									  <rect className={s.crossbg} width="22" height="22" fill="#fdc629"/>
									  <g className={s.crossicon}>
									  <g transform="translate(-10541.92 -11523.232)">
									    <line y2="12.719" transform="translate(10553.314 11528.267)" fill="none" stroke="#426680" strokeMiterlimit="10" strokeWidth="1"/>
									    <line x2="12.719" transform="translate(10546.955 11534.627)" fill="none" stroke="#426680" strokeMiterlimit="10" strokeWidth="1"/>
									  </g>
									  </g>
									</svg>
									
								</div>
								<div className={s.liftitemline2}>
									<div 
										className={s.litsitemtxt}
										dangerouslySetInnerHTML={{__html: d[1] }}
									/>
								</div>
							</div>
						))
					}
					</div>
				</div>
			</div>
		</section>
)}

export default Slide