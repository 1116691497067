import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Svg from '../../assets/art1_0.svg';


const Slide = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`1_0`}>
			<div className={s.art1_0} id="klift">
				<Svg />
			</div>
		</section>
)}

export default Slide