import React, { useEffect } from 'react'
import { gsap } from "gsap/dist/gsap";
import Tippy from '@tippyjs/react/headless';
import * as s from "./slide.module.css"
const tip = `.` + s.tippy

const Graph5 = (p) => {
	const DATA = p.data;
	const ID = p.dataid;

	let wd = (p.mobile)?260:550
	let hg = (p.mobile)?260:420

	const dX = (wd-2*25)/(DATA.dates.length-1);
	const dY = hg/100

	let P0 = ""
	let P1 = ""
	let P2 = ""
	let P3 = ""

	for(let i=0; i<DATA.dates.length; i++){
		let s = (i===0)?"M":" L "
		let x = 25 + dX*i

		let y0 = (100 - DATA.data[ID][0][i]) * dY
		let y1 = (100 - DATA.data[ID][1][i]) * dY
		let y2 = (100 - DATA.data[ID][2][i]) * dY
		let y3 = (100 - DATA.data[ID][3][i]) * dY
		
		P0 += (s + x + " " + y0)
		P1 += (s + x + " " + y1)
		P2 += (s + x + " " + y2)
		P3 += (s + x + " " + y3)
	}

	useEffect(() => {},[])

	function onMount() {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	    gsap.to(tip, {autoAlpha: 1, duration: 0.5, ease: `power2.out`});
	}
	function onHide({ unmount }) {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	}

	return (
		<section className={s.graph5 + ` graph5`} data-id={p.dataid}>
			<div 
				className={s.graphname + ` graphname`}
				data-id={`5`}
				dangerouslySetInnerHTML={{__html: DATA.countries[ID] }}
			/>

			<div className={s.graph5box}>
				<div className={s.axis5value + ` axis5value`} dangerouslySetInnerHTML={{__html: `100%` }} />
				<div className={s.graph5boxlines + ` graph5boxlines`}/>
				<svg 
					className={s.graph5svg}
					xmlns={`http://www.w3.org/2000/svg`}
					width={wd} 
					height={hg} 
					viewBox={`0 0 ${wd} ${hg}`} 
					preserveAspectRatio="none"
				>
					<defs>
						<clipPath id={`clip50_${p.dataid}`}>
							<rect x="0" y="0" width="100%" height="420" className={`clip5`} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id={`clip51_${p.dataid}`}>
							<rect x="0" y="0" width="100%" height="420" className={`clip5`} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id={`clip52_${p.dataid}`}>
							<rect x="0" y="0" width="100%" height="420" className={`clip5`} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id={`clip53_${p.dataid}`}>
							<rect x="0" y="0" width="100%" height="420" className={`clip5`} fill="#d5eaf4"/>
						</clipPath>
					</defs>

					<g clipPath={`url(#clip50_${p.dataid})`}>
						<path 
							d={P0}
							transform="translate(0 0)" 
							fill="none" 
							stroke="#FFF" 
							strokeLinecap="round" 
							strokeLinejoin="round" 
							strokeWidth="2"
							strokeDasharray="3,6"
							vectorEffect="non-scaling-stroke"
						/>
					</g>

					<g clipPath={`url(#clip51_${p.dataid})`}>
						<path 
							d={P1}
							transform="translate(0 0)" 
							fill="none" 
							stroke="#FFCD1C" 
							strokeLinecap="round" 
							strokeLinejoin="round" 
							strokeWidth="4"
							vectorEffect="non-scaling-stroke"
						/>
					</g>

					<g clipPath={`url(#clip52_${p.dataid})`}>
						<path 
							d={P2}
							transform="translate(0 0)" 
							fill="none" 
							stroke="#FFF" 
							strokeLinecap="round" 
							strokeLinejoin="round" 
							strokeWidth="2"
							vectorEffect="non-scaling-stroke"
						/>
					</g>

					<g clipPath={`url(#clip53_${p.dataid})`}>
						<path 
							d={P3}
							transform="translate(0 0)" 
							fill="none" 
							stroke="#FFF" 
							strokeLinecap="round" 
							strokeLinejoin="round" 
							strokeWidth="2"
							strokeDasharray="4,6"
							vectorEffect="non-scaling-stroke"
						/>
					</g>

				</svg>



				<div className={s.graphpoints}>

				{
					DATA.data[ID].map((d,i)=>(
						DATA.data[ID][i].map((q,j)=>(
							<Tippy
								key={j} 
								onMount={onMount}
								onHide={onHide}
								render={attrs => (
									<div 
										className={s.tippy}
										data-id={j} 
										{...attrs}
									>
										<span 
											className={s.tippyval}
											dangerouslySetInnerHTML={{__html: q + `%`}}
										/>
									</div>
								)}
							>
								<div 
									className={s.graphpoint}
									style={{
										top: (100 - q) * dY - 6 + "px",
										left: 25 + dX*j - 6 + "px"
									}}
									role = "button"
									tabIndex="0"
									aria-label="button"
								/>
							</Tippy>
						))
					))
				}


				{
					DATA.values.map((d,i)=>(
						<div 
							className={s.graphtype + ` graphtype`}
							id={`graphtype${ID}${i}`}
							data-id={i}
							key={i} 
							style={{
								top: (100 - DATA.data[ID][i][10]) * dY - 12 + "px"
							}}
							dangerouslySetInnerHTML={{__html: d }}
						/>
					))
				}

				</div>




			</div>

		
		</section>
)}

export default Graph5