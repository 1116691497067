// extracted by mini-css-extract-plugin
export const slide = "slide-module--slide--2Bogo";
export const slidecontainer = "slide-module--slidecontainer--3Mv7S";
export const art = "slide-module--art--1lmxr";
export const art1_0 = "slide-module--art1_0--2ldyk";
export const art2_1a = "slide-module--art2_1a--V5_vo";
export const art2_1b = "slide-module--art2_1b--1LXKM";
export const art2_1c = "slide-module--art2_1c--1i2BQ";
export const art2_1d = "slide-module--art2_1d--Bns0W";
export const art3_0 = "slide-module--art3_0--1XM4Y";
export const art4_0 = "slide-module--art4_0--ecsXS";
export const graph51 = "slide-module--graph51--1cvO-";
export const graph60 = "slide-module--graph60--Cffa3";
export const graph61 = "slide-module--graph61--1YCeC";
export const graph70 = "slide-module--graph70--2UuRT";
export const graph71 = "slide-module--graph71--3AmSW";
export const art8_0 = "slide-module--art8_0--21ueN";
export const txtxl = "slide-module--txtxl--3aPHD";
export const txtb = "slide-module--txtb--2kRIJ";
export const txt = "slide-module--txt--2UQJa";
export const slidesc = "slide-module--slidesc--3HrFr";
export const s40box = "slide-module--s40box--16gyO";
export const s40boxCity = "slide-module--s40boxCity--2AT9x";
export const s40boxPercent = "slide-module--s40boxPercent--2Y8He";
export const s40boxSource = "slide-module--s40boxSource--32AUI";
export const txtm = "slide-module--txtm--PxfOm";
export const txts = "slide-module--txts--3rTDk";
export const graphsbox = "slide-module--graphsbox--3K0aK";
export const graph = "slide-module--graph--3eYim";
export const graphname = "slide-module--graphname--3PKNI";
export const graphbox = "slide-module--graphbox--1Dtf-";
export const axisvalue = "slide-module--axisvalue--1ie46";
export const graphsvg = "slide-module--graphsvg--2ShUU";
export const graphtype = "slide-module--graphtype--2FMw2";
export const graphpoints = "slide-module--graphpoints--3cGtA";
export const graphpoint = "slide-module--graphpoint--ecMQN";
export const people = "slide-module--people--srQfB";
export const man = "slide-module--man--3FD5U";
export const bikes = "slide-module--bikes--1LeC1";
export const bike = "slide-module--bike--1vuZI";
export const planes = "slide-module--planes--2Ri2r";
export const plane = "slide-module--plane--2CUvY";
export const graphs5 = "slide-module--graphs5--1n0af";
export const graph5 = "slide-module--graph5--3Fi-k";
export const graph5box = "slide-module--graph5box--3z7gt";
export const graph5boxlines = "slide-module--graph5boxlines--3Z2zq";
export const axis5value = "slide-module--axis5value--2Qy91";
export const graph5svg = "slide-module--graph5svg--3dJyQ";
export const liftflex = "slide-module--liftflex--35Zhh";
export const liftlist = "slide-module--liftlist--1JLC9";
export const liftitem = "slide-module--liftitem--2-r2g";
export const liftitemactive = "slide-module--liftitemactive--n-mBE";
export const crossbg = "slide-module--crossbg--zZuMa";
export const crossicon = "slide-module--crossicon--1lxeg";
export const liftitemline1 = "slide-module--liftitemline1--1g59h";
export const litsitemh1 = "slide-module--litsitemh1--2ObyW";
export const liftitemline2 = "slide-module--liftitemline2--2AuQg";
export const litsitemtxt = "slide-module--litsitemtxt--3TjTJ";
export const cross = "slide-module--cross--2_nTW";
export const tippy = "slide-module--tippy--ADiUF";
export const tippyval = "slide-module--tippyval--1iFV4";
export const clip = "slide-module--clip--1UR4Z";
export const graphs = "slide-module--graphs--1mMs3";