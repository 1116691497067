import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Svg from '../../assets/art2_1d.svg';
import Plane from '../../assets/plane.svg';

const PLANES = Array.apply(null, Array(12)).map(function (x, i) { return i; })

const Slide = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`2_1d`}>
			<div className={s.slidecontainer}>
			<div 
				className={s.txt} 
				data-id={`2_1d0`} 
				dangerouslySetInnerHTML={{__html: p.data[0] }} 
			/>

			<div className={s.planes}>
				{
					PLANES.map((d,i)=>(
						<div key={i} className={s.plane + ` plane`}  data-id={i<3?"0":"1"}>
								<Plane />
						</div>
					))
				}
			</div>

			<div 
				className={s.txt + ' txt'} 
				data-id={`2_1d1`} 
				dangerouslySetInnerHTML={{__html: p.data[1] }} 
			/>

			<div 
				className={s.txtxl + ' txt'} 
				data-id={`2_1d2`} 
				dangerouslySetInnerHTML={{__html: p.data[2] }} 
			/>

			<div 
				className={s.txtb + ' txt'} 
				data-id={`2_1d3`} 
				dangerouslySetInnerHTML={{__html: p.data[3] }} 
			/>

			<div 
				className={s.txtm + ' txt'} 
				data-id={`2_1d4`} 
				dangerouslySetInnerHTML={{__html: p.data[4] }} 
			/>
			</div>

			<div className={s.art2_1d}>
			<div className={s.art}>
				<Svg />
			</div>
			</div>

		</section>
)}

export default Slide