import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Svg from '../../assets/art3_0.svg';

const Slide = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`3_0`}>
			<div className={s.slidecontainer} data-id={`3_0`}>
				<div 
					className={s.txt} 
					data-id={`3_0`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>
			</div>

			<div className={s.art3_0}>
				<div className={s.art}>
					<Svg />
				</div>
			</div>

		</section>
)}

export default Slide