import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Svg from '../../assets/art2_1b.svg';
import Man from '../../assets/man.svg';

const PEOPLE = Array.apply(null, Array(20)).map(function (x, i) { return i; })

const Slide = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`2_1b`}>
			
			<div className={s.slidecontainer}>
				<div 
					className={s.txt} 
					data-id={`2_1b0`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>
				<div 
					className={s.txt} 
					data-id={`2_1b1`} 
					dangerouslySetInnerHTML={{__html: p.data[1] }} 
				/>
			
				<div className={s.people}>
					{
						PEOPLE.map((d,i)=>(
							<div key={i} className={s.man + ` man`} data-id={i<10?"0":"1"}>
									<Man />
							</div>
						))
					}
				</div>

				<div 
					className={s.txtb + ' txt'} 
					data-id={`2_1b2`} 
					dangerouslySetInnerHTML={{__html: p.data[2] }} 
				/>

				<div 
					className={s.txtm + ' txt'} 
					data-id={`2_1b3`} 
					dangerouslySetInnerHTML={{__html: p.data[3] }} 
				/>
			</div>

			<div className={s.art2_1b}>
				<div className={s.art}>
					<Svg />
				</div>
			</div>


		</section>
)}

export default Slide