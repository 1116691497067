import React, { useEffect } from 'react'
import * as s from "./slides.module.css"

import Slide10 from "./slides/slide1_0"
import Slide20 from "./slides/slide2_0"
import Slide21a from "./slides/slide2_1a"
import Slide21b from "./slides/slide2_1b"
import Slide21c from "./slides/slide2_1c"
import Slide21d from "./slides/slide2_1d"
import Slide30 from "./slides/slide3_0"
import Slide40 from "./slides/slide4_0"
import Slide50 from "./slides/slide5_0"
import Slide51 from "./slides/slide5_1"
import Slide60 from "./slides/slide6_0"
import Slide61 from "./slides/slide6_1"
import Slide70 from "./slides/slide7_0"
import Slide71 from "./slides/slide7_1"
import Slide80 from "./slides/slide8_0"

const Slides = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slides} id={`allslides`}>
			<div id={`slide0_1`} className={s.detector}/>
			<Slide10 />
			<div id={`slide1_2`} className={s.detector}/>
			<Slide20 data={p.data.slides[1]} scale={p.scale} mobile={p.mobile}/>
			<div id={`slide2_3`} className={s.detector} />
			<section className={s.panels} id={`panels-container`}>
				<Slide21a data={p.data.slides[2][0]}/>
				<Slide21b data={p.data.slides[2][1]}/>
				<Slide21c data={p.data.slides[2][2]}/>
				<Slide21d data={p.data.slides[2][3]}/>
				<Slide30 data={p.data.slides[3]}/>
			</section>
			<div id={`slide3_4`} className={s.detector} />
			<Slide40 data={p.data.slides[4]}/>
			<div id={`slide4_5`} className={s.detector} />
			<Slide50 data={p.data.slides[5][0]} mobile={p.mobile}/>
			<Slide51 data={p.data.slides[5][1]}/>
			<div id={`slide5_6`} className={s.detector} />

			<Slide60 data={p.data.slides[6]}/>
			<Slide61 data={p.data.slides[6]}/>
			<Slide70 data={p.data.slides[7]}/>

			<div id={`slide6_7`} className={s.detector} />
			<Slide71 data={p.data.slides[7]}/>
			<div id={`slide7_8`} className={s.detector} />
			<Slide80 data={p.data.slides[8]}/>

		</section>
)}

export default Slides