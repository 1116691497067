import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Svg from '../../assets/art8_0.svg';

const Slide = (p) => {

	useEffect(() => {

	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`8_0`}>
			<div className={s.slidecontainer} data-id={`8_0`}>

				<div 
					className={s.txt + ` txt`} 
					data-id={`8_0`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>
				<div 
					className={s.txt + ` txt`} 
					data-id={`8_1`} 
					dangerouslySetInnerHTML={{__html: p.data[1] }} 
				/>
			</div>


			<div className={s.art8_0}>
				<div className={s.art} data-id={`8_0`}>
					<Svg />
				</div>
			</div>

		</section>
)}

export default Slide