import React, { useEffect } from 'react'
import * as s from "./bg.module.css"

import Svg from '../assets/finalclouds.svg';
import { withpath } from '../utils/index.js'
import Rotate from '../assets/rotate.svg';

const Bg = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.bg}>
			<div className={s.bgdark} />
			<div 
				className={s.bgsvg} 
				style={{backgroundImage: `url(${withpath(`/img/bg.svg`)})` }}
			/>
			<div id={`spinnerbox`}>
				<div id={`spinner`}/>
			</div>

			<div className={s.rotate}>
				<div className={s.rotatetxt} dangerouslySetInnerHTML={{__html: `Please Rotate Your Device` }} />
				<Rotate />
			</div>



			<div className={s.finalcloudsbox} id="fcbox">
				<div className={s.finalclouds}>
					<div className={s.art}>
						<Svg />
					</div>
				</div>
			</div>
			
		</section>
)}

export default Bg