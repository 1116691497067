import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Graph5 from "./graph5"


const Slide = (p) => {

	useEffect(() => {

	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`5_0`}>
			<div className={s.slidecontainer} data-id={`5_0`}>
				<div 
					className={s.txtb + ` txt`} 
					data-id={`5_0`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>
				<div 
					className={s.txtm} 
					data-id={`5_0`} 
					dangerouslySetInnerHTML={{__html: p.data[1] }} 
				/>

				<div className={s.graphs5 + ` graphs5`}>
					<Graph5 data={p.data[2]} dataid={0} mobile={p.mobile}/>
					<Graph5 data={p.data[2]} dataid={1} mobile={p.mobile}/>
				</div>
			</div>

		</section>
)}

export default Slide