import React, { useEffect } from 'react'
import * as s from "./slide.module.css"
import Graph from "./graph"

const Slide = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`2_0`}>

			<div className={s.slidecontainer} id={"slidecontainer2"} data-id={`2_0`}>
				<div 
					className={s.txt} 
					data-id={`2_0`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>
				<div 
					className={s.txt} 
					data-id={`2_0a`} 
					dangerouslySetInnerHTML={{__html: p.data[1] }} 
				/>
				<div className={s.graphs} id="graphs2">
					<div 
						className={s.txt + ` txt`}
						data-id={`2_0_1`} 
						dangerouslySetInnerHTML={{__html: p.data[2] }}
					/>

					<div 
						className={s.txts + ` txt`}
						data-id={`2_0`} 
						dangerouslySetInnerHTML={{__html: p.data[3] }}
					/>

					<div className={s.graphsbox}>
					{
						p.data[4].data.map((d,i)=>(
							<Graph
								mobile={p.mobile}
								key={i}
								dataid={i}
								data={d}
								dates={p.data[4].dates}
								city={p.data[4].cities[i]}
							/>
						))
					}
					</div>
				</div>
			</div>
		
		</section>
)}

export default Slide