import React, { useEffect, useState, useRef } from 'react'

// import ReactScrollWheelHandler from "react-scroll-wheel-handler";

import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin';
import { ScrollToPlugin } from 'gsap/all'

import Bg from './bg'
import Controls from './controls'
import Slides from './slides'

const isBrowser = typeof window !== "undefined"

let tl20
let tw
let tl2b
let tl2c
let tl2d
let tl4
let tl5
let tl5extra
let tl5extra2
let tl51
let tl6
let tl61
let tl70
let tl71
let tl80

let edge;

const Main = (p) => {

	gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MorphSVGPlugin);
	gsap.core.globals("ScrollTrigger", ScrollTrigger);
	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});
	gsap.defaults({overwrite: 'auto'});

	const controlsRef = useRef();
	const [mobile, setMobile] = useState(false);
	const [size, setSize] = useState(0)
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		function handleResize() {
			setSize(window.innerWidth)
			setMobile(window.innerWidth < 767)
			// if(!edge)
			// 	document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
		}
		function edgeCorrection(){

			let slide1 = document.querySelector(`.slide[data-id="1_0"]`);
			let slide2a = document.querySelector(`.slide[data-id="2_1a"]`);
			let slide2b = document.querySelector(`.slide[data-id="2_1b"]`);
			let slide2c = document.querySelector(`.slide[data-id="2_1c"]`);
			let slide2d = document.querySelector(`.slide[data-id="2_1d"]`);
			let slide4 = document.querySelector(`.slide[data-id="4_0"]`);

			slide1.style.height = (window.innerHeight - 126) +`px`;
			
			slide2a.style.minHeight = `650px`;
			slide2b.style.minHeight = `750px`;
			slide2c.style.minHeight = `750px`;
			slide2d.style.minHeight = `750px`;

			slide4.style.minHeight = window.innerHeight +`px`;
		}

		if(isBrowser){
			edge = window.navigator.userAgent.indexOf(`EdgiOS`) > -1
			if(edge && (window.innerWidth < 767)) edgeCorrection()

			window.addEventListener("resize", handleResize);
			window.addEventListener('orientationchange', handleResize)
			gsap.delayedCall(1, ()=>{
				handleResize();
				gsap.to(window, {duration: 0, scrollTo:0});
				setChecked(true);
			});
		}else{
			setChecked(true)
		}
	},[]);

	useEffect(() => {
		if(checked){

			ScrollTrigger.getAll().forEach(ST => ST.kill());
			if(tl20) tl20.pause(0).kill(true);
			if(tw) tw.pause(0).kill(true);
			if(tl2b) tl2b.pause(0).kill(true);
			if(tl2c) tl2c.pause(0).kill(true);
			if(tl2d) tl2d.pause(0).kill(true);
			if(tl4) tl4.pause(0).kill(true);
			if(tl5) tl5.pause(0).kill(true);
			if(tl5extra) tl5extra.pause(0).kill(true);
			if(tl5extra2) tl5extra2.pause(0).kill(true);
			if(tl51) tl51.pause(0).kill(true);
			if(tl6) tl6.pause(0).kill(true);
			if(tl61) tl61.pause(0).kill(true);
			if(tl70) tl70.pause(0).kill(true);
			if(tl71) tl71.pause(0).kill(true);
			if(tl80) tl80.pause(0).kill(true);

			// slide 2_0
			{
				let slide2 = document.querySelector(`#slidecontainer2`);
				let txt21 = document.querySelector(`.txt[data-id="2_0_1"]`);
				let txt2 = document.querySelector(`.txt[data-id="2_0"]`);
				let graphs = document.querySelectorAll(`.graph`);
				let clip2 = document.querySelectorAll(`.gr20clip`)
				gsap.set(txt21, {opacity: 0})
				gsap.set(txt2, {opacity: 0})
				gsap.set(graphs, {opacity: 0})
				gsap.set(clip2, {scaleX: 0, transformOrigin: `0% 50%`})

				tl20 = gsap.timeline({
						scrollTrigger: {
							id: `tl20`,
							trigger: slide2,
							markers: false,
							scrub: 0,
							start: () => `top bottom`,
							end: () => `bottom bottom`,
							invalidateOnRefresh: true,
							refreshPriority: 1,
							toggleActions: "play none none reset",
							onLeaveBack:()=> {
								gsap.killTweensOf([txt21, txt2])
								gsap.killTweensOf(graphs)
								gsap.killTweensOf(clip2)
								gsap.set(txt21, {opacity: 0})
								gsap.set(txt2, {opacity: 0})
								gsap.set(graphs, {opacity: 0})
								gsap.set(clip2, {scaleX: 0, transformOrigin: `0% 50%`})
							}
						}
					});
				tl20.call(()=>{
					if(tl20.scrollTrigger.direction !== -1){
						gsap.to(txt21,{opacity:1, duration: 1.5, ease: `power2.out`})
						gsap.to(txt2,{opacity:1, duration: 1.5, ease: `power2.out`})
					}
				}, null, 0.3);
				tl20.call(()=>{
					if(tl20.scrollTrigger.direction !== -1){
						gsap.to(graphs,{opacity:1, duration: 1.5, ease: `power2.out`, stagger: 0.2})
						gsap.to(clip2,{scaleX:1, duration: 1.5, ease: `power2.out`, delay: 0.1, stagger: 0.2})
					}
				}, null, 0.5);
				tl20.call(()=>{}, null, 1);
			}

			let w = document.querySelector("#panels-container");
			let wd = w.offsetWidth?w.offsetWidth/5:0
			let winw = document.body.clientWidth;
			let percent = ((wd - winw)/wd)*50;
			// slide panels
			{
				const panels = gsap.utils.toArray("#panels-container .slide");
				const panelsContainer = document.querySelector("#panels-container");
				if(!mobile){

					gsap.set(panels, {xPercent: -percent});

					tw = gsap.to(panels, {
						xPercent: -100 * ( panels.length - 1 ) - percent,
						ease: "none",
						scrollTrigger: {
							id: "panels",
							trigger: "#panels-container",
							pin: true,
							markers: false,
							scrub: 1,
							start: "top top",
							end: () =>  "+=" + (panelsContainer.offsetWidth - wd),
							invalidateOnRefresh: true,
							refreshPriority: 3,
							// anticipatePin: 0.1,
							// pinReparent: true,
						}
					});
				}else{
					gsap.set(panels, {xPercent: 0});
				}

				// slide 21ab
				let panel2b = document.querySelector(`.slide[data-id="2_1b"]`);
				let man = document.querySelectorAll(`.man[data-id="1"]`);
				let people = document.querySelectorAll(`.art21b_people1`);
				let txt2b2 = document.querySelector(`.txt[data-id="2_1b2"]`);
				let txt2b3 = document.querySelector(`.txt[data-id="2_1b3"]`);

				gsap.killTweensOf([man,people,txt2b2,txt2b3]);
				gsap.set(man, {scale: 0, opacity: 0, transformOrigin: "50% 100%"});
				gsap.set(people, {scale: 0.75, opacity: 0, transformOrigin: "50% 100%"});
				gsap.set(txt2b2, {scale: 0.75, opacity: 0, transformOrigin: "50% 50%"});
				gsap.set(txt2b3, {y: 0, opacity: 0, transformOrigin: "50% 50%"});

				tl2b = gsap.timeline({scrollTrigger: {
					id: `tl2b`,
					trigger: panel2b,
					markers: false,
					start: () => {
						let start = `top+=${3*wd/4} top`
						if(mobile) start = `top top+=200`
						return start
					},
					end: () => {
						let end = `bottom+=${1*wd} bottom`
						if(mobile) end = `bottom bottom-=200`
						return  end
					},
					scrub: 0
				}});
				tl2b.set(man, {scale: 0, opacity: 0});
				tl2b.set(people, {scale: 0.75, opacity: 0});
				tl2b.set(txt2b2, {scale: 0.75, opacity: 0});
				tl2b.set(txt2b3, {opacity: 0});
				tl2b.to(man, {scale: 1, opacity: 1, stagger: 0.1}, 0);
				tl2b.to(people, {scale: 1, opacity: 1, stagger: 0.2}, (mobile?1:0));
				tl2b.to(txt2b2, {scale: 1, opacity: 1}, (mobile?1:"<1"));
				tl2b.to(txt2b3, {opacity: 0.5},"<+=0");

				// slide 21c
				let panel2c = document.querySelector(`.slide[data-id="2_1c"]`);
				let bike = document.querySelectorAll(`.bike[data-id="1"]`);
				let biker = document.querySelectorAll(`.bike2`);
				let txt2c2 = document.querySelector(`.txt[data-id="2_1c2"]`);
				let txt2c3 = document.querySelector(`.txt[data-id="2_1c3"]`);
				let txt2c4 = document.querySelector(`.txt[data-id="2_1c4"]`);

				gsap.killTweensOf([bike,biker,txt2c2,txt2c3,txt2c4]);
				gsap.set(bike, {scale: 0, opacity: 0, transformOrigin: "50% 100%"});
				gsap.set(biker, {scale: 0.75, opacity: 0, transformOrigin: "50% 100%"});
				gsap.set(txt2c2, {opacity: 0, transformOrigin: "50% 50%"});
				gsap.set(txt2c3, {scale: 0.75, opacity: 0, transformOrigin: "50% 50%"});
				gsap.set(txt2c4, {opacity: 0, transformOrigin: "50% 50%"});

				tl2c = gsap.timeline({scrollTrigger: {
					id: `tl2c`,
					trigger: panel2c,
					markers: false,
					start: () => {
						let start = `top+=${7*wd/4} top`
						if(mobile) start = `top top+=200`
						return start
					},
					end: () => {
						let end = `bottom+=${2*wd} bottom`
						if(mobile) end = `bottom bottom-=200`
						return  end
					},
					scrub: 0
				}});
				tl2c.set(bike, {scale: 0, opacity: 0});
				tl2c.set(biker, {scale: 0.75, opacity: 0});
				tl2c.set(txt2c2, {opacity: 0});
				tl2c.set(txt2c3, {scale: 0.75, opacity: 0});
				tl2c.set(txt2c4, {opacity: 0});
				tl2c.to(bike, {scale: 1, opacity: 1, stagger: 0.3}, 0);
				tl2c.to(biker, {scale: 1, opacity: 1, stagger: 0.5}, (mobile?1:0));
				tl2c.to(txt2c2, {opacity: 1}, (mobile?1:"<1"));
				tl2c.to(txt2c3, {scale: 1, opacity: 1},"<+=0");
				tl2c.to(txt2c4, {opacity: 0.5},"<+=0");


				// slide 21d
				let panel2d = document.querySelector(`.slide[data-id="2_1d"]`);
				let plane = gsap.utils.toArray(`.plane[data-id="1"]`);
				plane.reverse();
				let txt2d1 = document.querySelector(`.txt[data-id="2_1d1"]`);
				let txt2d2 = document.querySelector(`.txt[data-id="2_1d2"]`);
				let txt2d3 = document.querySelector(`.txt[data-id="2_1d3"]`);
				let txt2d4 = document.querySelector(`.txt[data-id="2_1d4"]`);

				gsap.killTweensOf([plane,txt2d1,txt2d2,txt2d3,txt2d4]);
				gsap.set(plane, {scale: 1, opacity: 1, transformOrigin: "50% 100%"});
				gsap.set(txt2d1, {opacity: 0});
				gsap.set(txt2d2, {scale: 0.75, opacity: 0, transformOrigin: "50% 50%"});
				gsap.set(txt2d3, {opacity: 0});
				gsap.set(txt2d4, {opacity: 0});

				tl2d = gsap.timeline({scrollTrigger: {
					id: `tl2d`,
					trigger: panel2d,
					markers: false,
					start: () => {
						let start = `top+=${11*wd/4} top`
						if(mobile) start = `top top+=200`
						return start
					},
					end: () => {
						let end = `bottom+=${3*wd} bottom`
						if(mobile) end = `bottom bottom`
						return  end
					},
					scrub: 0
				}});
				tl2d.set(plane, {scale: 1, opacity: 1});
				tl2d.set(txt2d1, {opacity: 0});
				tl2d.set(txt2d2, {scale: 0.75, opacity: 0});
				tl2d.set(txt2d3, {opacity: 0});
				tl2d.set(txt2d4, {opacity: 0});
				tl2d.to(plane, {scale: 0, opacity: 0, stagger: 0.1}, 0);
				tl2d.to(txt2d1, {opacity: 1},"<1");
				tl2d.to(txt2d2, {scale: 1, opacity: 1},"<+=0");
				tl2d.to(txt2d3, {opacity: 1},"<+=0");
				tl2d.to(txt2d4, {opacity: 0.5},"<+=0");
			}

			// slide 4_0
			{
				let data40 = p.data.slides[4][4];
				const time1 = 0.3;

				let art40axis = document.querySelector(`#art40axis`);
				let art40axis2 = document.querySelector(`#art40axis2`);
				let art4buildings = document.querySelector(`#art4buildings`);
				let art40points = document.querySelector(`#art40points`);
				const txts40 = gsap.utils.toArray(".txt40");
				const art40 = gsap.utils.toArray(".art40city");
				const art40point = gsap.utils.toArray(".art40point");
				gsap.killTweensOf(txts40);
				gsap.killTweensOf(art40);
				gsap.killTweensOf(art40points);
				gsap.killTweensOf(art40axis);
				gsap.killTweensOf(art40axis2);
				gsap.killTweensOf(art4buildings);
				gsap.set(art40axis, {opacity: 0});
				gsap.set(art40axis2, {opacity: 0});
				gsap.set(art40points, {opacity: 0});
				gsap.set(art40, {opacity: 0});
				gsap.set(txts40, {opacity: 0});
				gsap.set(txts40[0], {opacity: 1});



				function stopall(){
					// gsap.killTweensOf(txts40)
					// gsap.killTweensOf(art40)
					// gsap.set(txts40, {opacity: 0});
					// gsap.set(art40, {opacity: 0});
				}


				function from0To1(){
					// console.log('01')
					stopall()
					gsap.to(txts40[0], {opacity: 0, duration: time1});
					gsap.to(txts40[1], {opacity: 1, duration: time1});
					gsap.to(art4buildings, {opacity: 0.3, duration: time1});
					gsap.to(art40points, {opacity: 1, duration: time1});
				}
				function from1To0(){
					// console.log('10')
					stopall()
					gsap.to(txts40[0], {opacity: 1, duration: time1});
					gsap.to(txts40[1], {opacity: 0, duration: time1});
					gsap.to(art4buildings, {opacity: 1, duration: time1});
					gsap.to(art40points, {opacity: 0, duration: time1});
				}

				function from1To2(){
					// console.log('12')
					stopall()
					gsap.to(txts40[1], {opacity: 0, duration: time1});
					gsap.to(txts40[4], {opacity: 1, duration: time1});
					gsap.to(art40[0], {opacity: 1, duration: time1});
					gsap.to(art40axis, {opacity: 1, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[0].data[0]/data40[0].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[0].data[1]/data40[0].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[0].data[2]/data40[0].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[0].data[3]/data40[0].data[0]), duration: time1});
				}
				function from2To1(){
					// console.log('21')
					stopall()
					gsap.to(txts40[1], {opacity: 1, duration: time1});
					gsap.to(txts40[4], {opacity: 0, duration: time1});
					gsap.to(art40[0], {opacity: 0, duration: time1});
					gsap.to(art40axis, {opacity: 0, duration: time1});
					gsap.to(art40point, {y: 230, duration: time1});
				}

				function from2To3(){
					// console.log('23')
					stopall()
					gsap.to(txts40[4], {opacity: 0, duration: time1});
					gsap.to(art40[0], {opacity: 0, duration: time1});
					gsap.to(txts40[5], {opacity: 1, duration: time1});
					gsap.to(art40[1], {opacity: 1, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[1].data[0]/data40[1].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[1].data[1]/data40[1].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[1].data[2]/data40[1].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[1].data[3]/data40[1].data[0]), duration: time1});
				}
				function from3To2(){
					// console.log('32')
					stopall()
					gsap.to(txts40[4], {opacity: 1, duration: time1});
					gsap.to(art40[0], {opacity: 1, duration: time1});
					gsap.to(txts40[5], {opacity: 0, duration: time1});
					gsap.to(art40[1], {opacity: 0, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[0].data[0]/data40[0].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[0].data[1]/data40[0].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[0].data[2]/data40[0].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[0].data[3]/data40[0].data[0]), duration: time1});
				}

				function from3To4(){
					// console.log('34')
					stopall()
					gsap.to(txts40[5], {opacity: 0, duration: time1});
					gsap.to(art40[1], {opacity: 0, duration: time1});
					gsap.to(txts40[6], {opacity: 1, duration: time1});
					gsap.to(art40[2], {opacity: 1, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[2].data[0]/data40[2].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[2].data[1]/data40[2].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[2].data[2]/data40[2].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[2].data[3]/data40[2].data[0]), duration: time1});
				}
				function from4To3(){
					// console.log('43')
					stopall()
					gsap.to(txts40[5], {opacity: 1, duration: time1});
					gsap.to(art40[1], {opacity: 1, duration: time1});
					gsap.to(txts40[6], {opacity: 0, duration: time1});
					gsap.to(art40[2], {opacity: 0, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[1].data[0]/data40[1].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[1].data[1]/data40[1].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[1].data[2]/data40[1].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[1].data[3]/data40[1].data[0]), duration: time1});
				}

				function from4To5(){
					// console.log('45')
					stopall()
					gsap.to(txts40[6], {opacity: 0, duration: time1});
					gsap.to(art40[2], {opacity: 0, duration: time1});
					gsap.to(txts40[2], {opacity: 1, duration: time1});
					gsap.to(art40axis, {opacity: 0, duration: time1});
					gsap.to(art40points, {opacity: 0, duration: time1});
				}
				function from5To4(){
					// console.log('54')
					stopall()
					gsap.to(txts40[6], {opacity: 1, duration: time1});
					gsap.to(art40[2], {opacity: 1, duration: time1});
					gsap.to(txts40[2], {opacity: 0, duration: time1});
					gsap.to(art40axis, {opacity: 1, duration: time1});
					gsap.to(art40points, {opacity: 1, duration: time1});
				}

				function from5To6(){
					// console.log('56')
					stopall()
					gsap.to(txts40[2], {opacity: 0, duration: time1});
					gsap.to(art40axis2, {opacity: 1, duration: time1});
					gsap.to(txts40[7], {opacity: 1, duration: time1});
					gsap.to(art40[3], {opacity: 1, duration: time1});
					gsap.to(art40points, {opacity: 1, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[3].data[0]/data40[3].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[3].data[1]/data40[3].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[3].data[2]/data40[3].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[3].data[3]/data40[3].data[0]), duration: time1});
				}
				function from6To5(){
					// console.log('65')
					stopall()
					gsap.to(txts40[2], {opacity: 1, duration: time1});
					gsap.to(art40axis2, {opacity: 0, duration: time1});
					gsap.to(txts40[7], {opacity: 0, duration: time1});
					gsap.to(art40[3], {opacity: 0, duration: time1});
					gsap.to(art40points, {opacity: 0, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[2].data[0]/data40[2].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[2].data[1]/data40[2].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[2].data[2]/data40[2].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[2].data[3]/data40[2].data[0]), duration: time1});
				}

				function from6To7(){
					// console.log('67')
					stopall()
					gsap.to(txts40[7], {opacity: 0, duration: time1});
					gsap.to(art40[3], {opacity: 0, duration: time1});
					gsap.to(txts40[8], {opacity: 1, duration: time1});
					gsap.to(art40[4], {opacity: 1, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[4].data[0]/data40[4].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[4].data[1]/data40[4].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[4].data[2]/data40[4].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[4].data[3]/data40[4].data[0]), duration: time1});
				}
				function from7To6(){
					// console.log('76')
					stopall()
					gsap.to(txts40[7], {opacity: 1, duration: time1});
					gsap.to(art40[3], {opacity: 1, duration: time1});
					gsap.to(txts40[8], {opacity: 0, duration: time1});
					gsap.to(art40[4], {opacity: 0, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[3].data[0]/data40[3].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[3].data[1]/data40[3].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[3].data[2]/data40[3].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[3].data[3]/data40[3].data[0]), duration: time1});
				}

				function from7To8(){
					// console.log('78')
					stopall()
					gsap.to(txts40[8], {opacity: 0, duration: time1});
					gsap.to(art40[4], {opacity: 0, duration: time1});
					gsap.to(txts40[9], {opacity: 1, duration: time1});
					gsap.to(art40[5], {opacity: 1, duration: time1});
					gsap.to(art40point, {opacity: 1, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[5].data[0]/data40[5].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[5].data[1]/data40[5].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[5].data[2]/data40[5].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[5].data[3]/data40[5].data[0]), duration: time1});
				}
				function from8To7(){
					// console.log('87')
					stopall()
					gsap.to(txts40[8], {opacity: 1, duration: time1});
					gsap.to(art40[4], {opacity: 1, duration: time1});
					gsap.to(txts40[9], {opacity: 0, duration: time1});
					gsap.to(art40[5], {opacity: 0, duration: time1});
					// gsap.to(art40point, {opacity: 0, duration: time1});
					gsap.to(art40point[0], {y: 460 * (1 - data40[4].data[0]/data40[4].data[0]), duration: time1});
					gsap.to(art40point[1], {y: 460 * (1 - data40[4].data[1]/data40[4].data[0]), duration: time1});
					gsap.to(art40point[2], {y: 460 * (1 - data40[4].data[2]/data40[4].data[0]), duration: time1});
					gsap.to(art40point[3], {y: 460 * (1 - data40[4].data[3]/data40[4].data[0]), duration: time1});
				}

				function from8To9(){
					// console.log('89')
					stopall()
					gsap.to(txts40[9], {opacity: 0, duration: time1});
					gsap.to(art40[5], {opacity: 0, duration: time1});
					gsap.to(txts40[3], {opacity: 1, duration: time1});
					gsap.to(art40axis2, {opacity: 0, duration: time1});
					gsap.to(art40point, {opacity: 0, duration: time1});
					controlsRef.current.newSection(4)
					// setIs4(false);
				}
				function from9To8(){
					// console.log('98')
					stopall()
					gsap.to(txts40[9], {opacity: 1, duration: time1});
					gsap.to(art40[5], {opacity: 1, duration: time1});
					gsap.to(txts40[3], {opacity: 0, duration: time1});
					gsap.to(art40axis2, {opacity: 1, duration: time1});
					gsap.to(art40point, {opacity: 1, duration: time1});
					controlsRef.current.newSection(3)
					// setIs4(true);
				}

				tl4 = gsap.timeline({
					scrollTrigger: {
						id: `tl4`,
						trigger: `.slide[data-id="4_0"]`,
						pin: true,
						markers: false,
						start: "top top",
						end: () => `+=${mobile?10000:20000}`,
						invalidateOnRefresh: true,
						refreshPriority: 2,
						scrub: true,
						anticipatePin:1,
						snap: mobile?false:{
							snapTo:[0.01,0.025,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1],
							duration: {min: 0.1, max: 0.3},
							delay: 0,
						},
						onSnapComplete: ({progress, direction, isActive}) => console.log(progress, direction, isActive)
					}
				});


				tl4.call(()=>{ }, null, 0)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1)
						from0To1()
					else
						from1To0()
				},null,0.05)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1)
						from1To2()
					else
						from2To1()
				},null,0.15)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1){
						from2To3()
					}else{
						from3To2()
					}
				},null,0.25)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1){
						from3To4()
					}else{
						from4To3()
					}
				},null,0.35)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1){
						from4To5()
					}else{
						from5To4()
					}
				},null,0.45)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1){
						from5To6()
					}else{
						from6To5()
					}
				},null,0.55)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1)
						from6To7();
					else
						from7To6();
				},null,0.65)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1)
						from7To8()
					else
						from8To7()
				},null,0.75)

				tl4.call(()=>{
					if(tl4.scrollTrigger.direction !== -1)
						from8To9()
					else
						from9To8()
				},null,0.85)

				tl4.call(()=>{ }, null, 0.95)
				// tl4.addLabel("95", 0.95)

				tl4.call(()=>{ }, null, 1)
				// tl4.addLabel("1", 1)
			}

			//slide5_0
			{
				let g50 = document.querySelector(`.graph5[data-id="0"]`);
				let g51 = document.querySelector(`.graph5[data-id="1"]`);
				gsap.set(g50, {autoAlpha: 1});
				gsap.set(g51, {autoAlpha: (mobile?1:0)});

				let clips0 = document.querySelectorAll(`.graph5[data-id="0"] .clip5`);
				let names0 = document.querySelectorAll(`.graph5[data-id="0"] .graphtype`);
				let clips1 = document.querySelectorAll(`.graph5[data-id="1"] .clip5`);
				let names1 = document.querySelectorAll(`.graph5[data-id="1"] .graphtype`);

				// let axisval0 = document.querySelector(`.graph5[data-id="0"] .axis5value`);
				// let axisval1 = document.querySelector(`.graph5[data-id="1"] .axis5value`);
				// let boxlines0 = document.querySelector(`.graph5[data-id="0"] .graph5boxlines`);
				// let boxlines1 = document.querySelector(`.graph5[data-id="1"] .graph5boxlines`);
				// gsap.set(axisval0, {opacity: (mobile?1:0)})
				// gsap.set(axisval1, {opacity: (mobile?1:0)})
				// gsap.set(boxlines0, {opacity: (mobile?1:0)})
				// gsap.set(boxlines1, {opacity: (mobile?1:0)})

				let graphname0 = document.querySelector(`.graph5[data-id="0"] .graphname`);
				let graphname1 = document.querySelector(`.graph5[data-id="1"] .graphname`);
				gsap.set(graphname0, {opacity: (mobile?1:0)})
				gsap.set(graphname1, {opacity: (mobile?1:0)})

				gsap.set(clips0, {scaleX:0, transformOrigin:'0% 50%'});
				gsap.set(clips1, {scaleX:0, transformOrigin:'0% 50%'});
				gsap.set(names0, {opacity:0});
				gsap.set(names1, {opacity:0});


				
				if(!mobile){

					tl5 = gsap.timeline({
						scrollTrigger: {
							id: `tl5`,
							trigger: `.slide[data-id="5_0"]`,
							start: "top top",
							end: "+=1000",
							scrub: 0,
							pin: true,
							markers: false,
							invalidateOnRefresh: true,
							refreshPriority: 1,
							// anticipatePin: 1,
							toggleActions: "play none none none",
							onLeaveBack:()=> {},
							// onUpdate: self => console.log("progress", self.progress),
						}
					});
					tl5.call(()=>{
						// if(tl5.scrollTrigger.direction !== -1){
							gsap.to(graphname0, {opacity: 1, duration: 0.5})
							gsap.to(clips0, {scaleX: 1, duration: 1.5, stagger: 0.1});
							gsap.to(names0, {opacity: 1, duration: 0.5, delay: 1})
						// }
					}, null, 0);

					tl5.call(()=>{
						if(tl5.scrollTrigger.direction !== -1){
							gsap.to(g50, {autoAlpha: 0, duration: 0.5});
							gsap.to(g51, {autoAlpha: 1, duration: 0.5});

							gsap.to(graphname1, {opacity: 1, duration: 0.5})
							gsap.to(clips1, {scaleX: 1, duration: 1.5, stagger: 0.1})
							gsap.to(names1, {opacity: 1, duration: 0.5, delay: 1})
						}else{
							gsap.to(g50, {autoAlpha: 1, duration: 0.5});
							gsap.to(g51, {autoAlpha: 0, duration: 0.5});

							gsap.to(graphname0, {opacity: 1, duration: 0.5})
							gsap.to(clips0, {scaleX: 1, duration: 1.5, stagger: 0.1});
							gsap.to(names0, {opacity: 1, duration: 0.5, delay: 1})
						}
					}, null, 0.5);

					tl5.call(()=>{

					}, null, 1);

				}else{
					
					tl5 = gsap.timeline({
						scrollTrigger: {
							id: `tl5`,
							trigger: `.graphs5`,
							start: "top bottom",
							end: "bottom bottom",
							scrub: 0,
							markers: false,
							invalidateOnRefresh: true,
							refreshPriority: 1,
							toggleActions: "play none none none",
							onLeaveBack:()=> {
								gsap.set(clips0, {scaleX:0, transformOrigin:'0% 50%'});
								gsap.set(clips1, {scaleX:0, transformOrigin:'0% 50%'});
								gsap.set(names0, {opacity:0});
								gsap.set(names1, {opacity:0});
							}
						}
					});
					tl5.call(()=>{
						if(tl5.scrollTrigger.direction !== -1){
							gsap.to(clips0, {scaleX: 1, duration: 1.2, ease:`power1.easeOut`, stagger: 0.1});
							gsap.to(names0, {opacity: 1, duration: 0.5, ease:`power1.easeOut`, delay: 1})
						}
					}, null, 0.5);
					tl5.call(()=>{
						if(tl5.scrollTrigger.direction !== -1){
							gsap.to(clips1, {scaleX: 1, duration: 1.2, ease:`power1.easeOut`, stagger: 0.1})
							gsap.to(names1, {opacity: 1, duration: 0.5, ease:`power1.easeOut`, delay: 1})
						}
					}, null, 1);
					
				}






				if(!mobile){

					let fcbox = document.querySelector(`#fcbox`);
					gsap.set(fcbox, {y: 0})
					tl5extra = gsap.timeline({
						scrollTrigger: {
							id: `tl5extra`,
							trigger: `.slide[data-id="5_0"]`,
							start: "top bottom",
							end: "bottom bottom",
							scrub: 0,
							markers: false,
							invalidateOnRefresh: true,
							refreshPriority: 1,
							toggleActions: "play none none none",
							onLeaveBack:()=> {
							}
						}
					});
					tl5extra.to(fcbox, {y: `-100vh`})

					tl5extra2 = gsap.timeline({
						scrollTrigger: {
							id: `tl5extra2`,
							trigger: `.slide[data-id="8_0"]`,
							start: "bottom bottom",
							end: () => `bottom+=200 bottom`,
							scrub: 0,
							markers: false,
							invalidateOnRefresh: true,
							refreshPriority: 1,
							toggleActions: "play none none none",
							onLeaveBack:()=> {
							}
						}
					});
					tl5extra2.set(fcbox, {opacity: 1})
					tl5extra2.to(fcbox, {opacity: 0})
				}
			}

			//slide5_1
			{
				const PATHS = [
					`M7478.91,10365.751v106.721h150.935v-265Z`,
					`M7651.691,10365.751v106.721h150.935v-246.707Z`,
					`M7826.178,10365.751v106.721h150.935v-155.629Z`,
					`M7999.841,10365.751v106.721h150.935v-422.483Z`
				]
				const STARTPATHS = [
					`M 7478.91 10365.751 v 106.721 h 150.935 v -107.472 Z`,
					`M 7651.691 10365.751 v 106.721 h 150.935 v -107.472 Z`,
					`M 7826.178 10365.751 v 106.721 h 150.935 v -106.472 Z`,
					`M 7999.841 10365.751 v 106.721 h 150.935 v -106.472 Z`,
				]
				const VALS = [ 134, 126, 41, 301 ]
				// const delay = 0.0;
				const time51 = 2.0;


				const txt = document.querySelector(`.txt[data-id="5_1"]`);

				const path0 = document.querySelector(`#svg51 #art51path0`);
				const path1 = document.querySelector(`#svg51 #art51path1`);
				const path2 = document.querySelector(`#svg51 #art51path2`);
				const path3 = document.querySelector(`#svg51 #art51path3`);

				const span0 = document.querySelector(`#svg51 #art51tspan0`);
				const span1 = document.querySelector(`#svg51 #art51tspan1`);
				const span2 = document.querySelector(`#svg51 #art51tspan2`);
				const span3 = document.querySelector(`#svg51 #art51tspan3`);
				
				let counter0 = { var: 0 };
				let counter1 = { var: 0 };
				let counter2 = { var: 0 };
				let counter3 = { var: 0 };


				gsap.set(txt, {opacity: ((mobile)?1:0)})

				gsap.set(span0, {opacity: 0});
				gsap.set(span1, {opacity: 0});
				gsap.set(span2, {opacity: 0});
				gsap.set(span3, {opacity: 0});

				span0.textContent = ``;
				span1.textContent = ``;
				span2.textContent = ``;
				span3.textContent = ``;

				tl51 = gsap.timeline({
					scrollTrigger:{
						id: `tl51`,
						trigger: `#graph51`,
						start: `top bottom`,
						end: `bottom bottom`,
						markers: false,
						scrub: 0,
						invalidateOnRefresh: true,
						refreshPriority: 1,
						toggleActions: "play none none reset",
						onLeaveBack:()=> {

							gsap.killTweensOf([txt]);
							gsap.set(txt, {opacity: ((mobile)?1:0)})

							gsap.killTweensOf([counter0,counter1,counter2,counter3]);
							span0.textContent = ``;
							span1.textContent = ``;
							span2.textContent = ``;
							span3.textContent = ``;

							gsap.killTweensOf([span0,span1,span2,span3]);
							gsap.set(span0, {opacity: 0});
							gsap.set(span1, {opacity: 0});
							gsap.set(span2, {opacity: 0});
							gsap.set(span3, {opacity: 0});

							gsap.set(path0, {morphSVG: STARTPATHS[0]})
							gsap.set(path1, {morphSVG: STARTPATHS[1]})
							gsap.set(path2, {morphSVG: STARTPATHS[2]})
							gsap.set(path3, {morphSVG: STARTPATHS[3]})

							counter0 = { var: 0 }
							counter1 = { var: 0 }
							counter2 = { var: 0 }
							counter3 = { var: 0 }
						}
					}
				});

				tl51.call(()=>{
					if(tl51.scrollTrigger.direction !== -1){
						gsap.to(txt, {opacity: 1, duration: 1.5, ease: `power2.out`})
					}
				}, null, 0.1)

				tl51.call(()=>{

					if(tl51.scrollTrigger.direction !== -1){
						gsap.to(path0, {morphSVG: PATHS[0], duration: time51*(VALS[0]/301), ease: `power1.out`});
						gsap.to(span0, {opacity:1, duration: 0.5, ease: `power1.out`});
						gsap.to(counter0, {var: VALS[0], duration: time51*(VALS[0]/301), ease: `power1.out`, 
							onUpdate: function () { span0.textContent = `+` + Math.ceil(counter0.var) + `%`; }, 
						});

						gsap.to(path1, {morphSVG: PATHS[1], duration: time51*(VALS[1]/301), ease: `power1.out`});
						gsap.to(span1, {opacity:1, duration: 0.5, ease: `power1.out`});
						gsap.to(counter1, {var: VALS[1], duration: time51*(VALS[1]/301), ease: `power1.out`, 
							onUpdate: function () { span1.textContent = `+` + Math.ceil(counter1.var) + `%`; }, 
						});

						gsap.to(path2, {morphSVG: PATHS[2], duration: time51*(VALS[2]/301), ease: `power1.out`});
						gsap.to(span2, {opacity:1, duration: 0.5, ease: `power1.out`});
						gsap.to(counter2, {var: VALS[2], duration: time51*(VALS[2]/301), ease: `power1.out`, 
							onUpdate: function () { span2.textContent = `+` + Math.ceil(counter2.var) + `%`; }, 
						});

						gsap.to(path3, {morphSVG: PATHS[3], duration: time51*(VALS[3]/301), ease: `power1.out`});
						gsap.to(span3, {opacity:1, duration: 0.5, ease: `power1.out`});
						gsap.to(counter3, {var: VALS[3], duration: time51*(VALS[3]/301), ease: `power1.out`, 
							onUpdate: function () { span3.textContent = `+` + Math.ceil(counter3.var) + `%`; }, 
						});
					}
				}, null, 1)
			}

			// slides6_0
			{
				const slide6 = document.querySelector(`#slidecontainer6`)
				const art6 = document.querySelector(`#art6`);
				const clip6 = document.querySelector(`#clip6`);
				const txt6 = document.querySelector(`#art6txt`);

				gsap.set(art6, {scale: 1.5, transformOrigin: "50% 0"});
				gsap.set(clip6, {scaleY: 0, transformOrigin: "50% 0"});
				gsap.set(txt6, {opacity: (mobile)?1:0});

				tl6 = gsap.timeline({
					scrollTrigger:{
						id: `tl6`,
						trigger: slide6,
						start: `top center`,
						end: `bottom bottom`,
						markers: false,
						scrub: 0,
						invalidateOnRefresh: true,
						refreshPriority: 1,
						toggleActions: "play none none reset",
					}
				});
				tl6.set(clip6, {scaleY:0, transformOrigin:'50% 0%'});
				tl6.set(txt6, {opacity: (mobile)?1:0});
				tl6.to(art6, {scale: 1, duration: 1}, 0)
				tl6.call(()=>{
					gsap.to(clip6, {scaleY: 1, duration: 2})
					gsap.to(txt6, {opacity: 1, duration: 1.5})
				}, null, 1);
			}

			// slides6_1
			{

				const slide61 = document.querySelector(`#slidecontainer61`)
				const clip61 = document.querySelector(`#clip61`);
				const clip62 = document.querySelector(`#clip62`);
				const txt61 = document.querySelector(`#art61txt`);
				
				gsap.set(clip61, {scaleX: 0, transformOrigin: "0% 50%"});
				gsap.set(clip62, {scaleX: 0, transformOrigin: "0% 50%"});
				gsap.set(txt61, {opacity: ((mobile)?1:0)});

				tl61 = gsap.timeline({
					scrollTrigger:{
						id: `tl61`,
						trigger: slide61,
						start: `top bottom`,
						end: `bottom bottom`,
						markers: false,
						scrub: 0,
						invalidateOnRefresh: true,
						refreshPriority: 1,
						toggleActions: "play none none reset",
						onLeaveBack:()=> {
							gsap.killTweensOf([clip61,clip62,txt61])
							gsap.set(clip61, {scaleX: 0, transformOrigin: "0% 50%"});
							gsap.set(clip62, {scaleX: 0, transformOrigin: "0% 50%"});
							gsap.set(txt61, {opacity: ((mobile)?1:0)});
						}
					}
				});
				// tl61.set(clip61, {scaleX:0, transformOrigin:'0% 50%'});
				// tl61.set(clip62, {scaleX:0, transformOrigin:'0% 50%'});
				// tl61.set(txt61, {opacity: ((mobile)?1:0)});
				tl61.call(()=>{
					gsap.to(clip61, {scaleX: 1, duration: 1.5})
				}, null, 0.2);
				tl61.call(()=>{
					gsap.to(clip62, {scaleX: 1, duration: 1.5})
				}, null, 0.5);
				tl61.call(()=>{
					gsap.to(txt61, {opacity: 1, duration: 1.5})
				}, null, 0.9);
			}

			// slides7_0
			{
				const slide70 = document.querySelector(`#slidecontainer70`)

				const reflexes = document.querySelectorAll(`#svg7 .art7reflex`);
				const objects = document.querySelectorAll(`#svg7 .art7object`);

				// const man = document.querySelector(`#svg7 #art7man`);
				// const manreflex = document.querySelector(`#svg7 #art7manreflex`);
				
				const scheme7 = document.querySelector(`#svg7 #art7scheme`);
				const wifi7 = document.querySelectorAll(`#svg7 #art7wifi path`);

				const txt7 = document.querySelector(`.txt[data-id="7_0"]`);

				gsap.set(scheme7, {opacity: 0, scale: 0.75, transformOrigin: `50% 50%`})
				gsap.set(txt7, {opacity: ((mobile)?1:0)})
				
				gsap.set(objects, {opacity: 0, scale:0.9, y: -30, transformOrigin: `50% 0%`})
				gsap.set(reflexes, {opacity: 0, scale:0.5, transformOrigin: `50% 0%`})


				gsap.set(wifi7, {scale: 1, opacity: 1, transformOrigin: `50% 50%`})
				gsap.to(wifi7, {scale: 1.3, opacity: 0, stagger: 0.1, duration: 0.5, ease: `power2.easeOut`, repeat: -1});


				tl70 = gsap.timeline({
					scrollTrigger:{
						id: `tl70`,
						trigger: slide70,
						start: `top bottom`,
						end: `bottom bottom`,
						markers: false,
						scrub: 0,
						invalidateOnRefresh: true,
						refreshPriority: 1,
						toggleActions: "play none none reset",
						onLeaveBack:()=> {
							gsap.killTweensOf([txt7,scheme7,objects,reflexes])
							gsap.set(scheme7, {opacity: 0, scale: 0.75, transformOrigin: `50% 50%`})
							gsap.set(txt7, {opacity: ((mobile)?1:0)});
							gsap.set(objects, {opacity: 0, scale:0.9, y: -30, transformOrigin: `50% 0%`})
							gsap.set(reflexes, {opacity: 0, scale:0.5, transformOrigin: `50% 0%`})
						}
					}
				});

				tl70.set(txt7, {opacity: ((mobile)?1:0)});
				tl70.call(()=>{
					if(tl70.scrollTrigger.direction !== -1){
						gsap.to(scheme7, {scale: 1, opacity: 1, duration: 1, ease: `power2.out`, transformOrigin: `50% 50%`})
						gsap.to(objects, {scale: 1, opacity: 1, y: 0, duration: 0.5, ease: `power2.out`, stagger: 0.1, delay: 0.5})
						gsap.to(reflexes, {scale: 1, opacity: 1, duration: 0.5, ease: `power2.out`, stagger: 0.1, delay: 0.5})
					}
				}, null, 0.4);

				tl70.call(()=>{
					gsap.to(txt7, {opacity: 1, duration: 1})
				}, null, 1);
			}

			// slides7_1
			{
				const slide71 = document.querySelector(`#slidecontainer71`)
				const wifi71 = document.querySelectorAll(`#svg71 #liftwifi path`);
				gsap.set(wifi71, {scale: 1, opacity: 1, transformOrigin: `50% 50%`})
				gsap.to(wifi71, {scale: 1.3, opacity: 0, stagger: 0.1, duration: 0.5, ease: `power2.easeOut`, repeat: -1});
				let liftdoor71 = document.querySelector("#svg71 #liftdoor70");
				let liftrealdoor71 = document.querySelector("#svg71 #realdoor");
				let lift71 = document.querySelector("#svg71");
				gsap.set(liftdoor71, {scaleX: 0, transformOrigin: "50% 50%"});
				gsap.set(liftrealdoor71, {scaleX: 0.02, transformOrigin: "50% 50%"});
				gsap.set(lift71, {y: (mobile)?0:500})
				
				tl71 = gsap.timeline({
					scrollTrigger:{
						id: `tl71`,
						trigger: slide71,
						start: `top bottom`,
						end: `top 30%`,
						markers: false,
						scrub: 0,
						invalidateOnRefresh: true,
						refreshPriority: 1,
						toggleActions: "play none none reset",
						onLeaveBack:()=> {
							gsap.killTweensOf([liftdoor71,liftrealdoor71])
							gsap.set(liftdoor71, {scaleX: 0, transformOrigin: "50% 50%"});
							gsap.set(liftrealdoor71, {scaleX: 0.02, transformOrigin: "50% 50%"});
						}
					}
				});
				tl71.set(lift71, {y: (mobile)?0:500})
				tl71.to(lift71, {y: 0, duration: 1}, 0)
				tl71.call(()=>{
					if(tl71.scrollTrigger.direction !== -1){
						gsap.to(liftdoor71, {duration: 1, scaleX: 1, ease: "power2.inOut"});
						gsap.to(liftrealdoor71, {duration: 1, scaleX: 1, ease: "power2.inOut"});
					}
				}, null, 1);
			}

			// slides8
			{
				let slide8 = document.querySelector(`.slide[data-id="8_0"]`)
				let copter8 = document.querySelector(`#helicopter`);
				let peoples8 = document.querySelectorAll(`#art8peoples g path`)
				let lifts8 = document.querySelectorAll(`.art8lift`)
				let txt80 = document.querySelector(`.txt[data-id="8_0"]`)
				let txt81 = document.querySelector(`.txt[data-id="8_1"]`)
				let finalbike = document.querySelector(`#finalbike`)
				let finalman = document.querySelector(`#finalman`)

				gsap.set(txt80, {opacity: ((mobile)?1:0)});
				gsap.set(txt81, {opacity: ((mobile)?1:0)});
				gsap.set(copter8, {opacity: 0});
				gsap.set(peoples8, {scale: 0.75, opacity: 0, transformOrigin: "50% 100%"});
				gsap.set(finalbike, {x: (mobile)?1200:600})
				gsap.set(finalman, {x: (mobile)?700:0})
				gsap.set(lifts8[0], {y: 200})
				gsap.set(lifts8[1], {y: 100})
				gsap.set(lifts8[2], {y: -200})
				gsap.set(lifts8[3], {y: 300})

				tl80 = gsap.timeline({
					scrollTrigger:{
						id: `tl80`,
						trigger: slide8,
						start: `top bottom`,
						end: `bottom bottom`,
						markers: false,
						scrub: 0,
						invalidateOnRefresh: true,
						refreshPriority: 1,
						toggleActions: "play none none reset",
						onLeaveBack:()=> {
							gsap.set(txt80, {opacity: (mobile)?1:0})
							gsap.set(txt81, {opacity: (mobile)?1:0})
							gsap.to(copter8, {opacity: 0, duration: 0.5})
							gsap.set(peoples8, {opacity: 0, scale: 0.75})
							gsap.set(lifts8[0], {y: 200})
							gsap.set(lifts8[1], {y: 100})
							gsap.set(lifts8[2], {y: -200})
							gsap.set(lifts8[3], {y: 300})
						}
					}
				});

				tl80.call(()=>{
					if(tl80.scrollTrigger.direction !== -1)
						gsap.to(txt80, {opacity: 1, duration: 1.5, ease: `power2.out`})
				}, null, 0.2);
				tl80.call(()=>{
					if(tl80.scrollTrigger.direction !== -1)
						gsap.to(txt81, {opacity: 1, duration: 1.5, ease: `power2.out`})
				}, null, 0.3);

				tl80.call(()=>{
					if(tl80.scrollTrigger.direction !== -1){
						gsap.to(copter8, {opacity: 1, duration: 1.5, ease: `power2.out`})
						gsap.to(lifts8, {y: 0, duration: 2.5, ease: `power2.out`, stagger: 0.2})
						gsap.to(peoples8, {scale: 1, opacity: 0.5, duration: 0.5, ease: `power2.out`, stagger: 0.1})
						gsap.to(finalbike, {x:(mobile)?600:0, duration: 2.0, ease: `power1.out`})
					}
				}, null, 0.6);
				tl80.call(()=>{

				}, null, 1);
			}

			let st12 = ScrollTrigger.getById(`st1_2`)
			if(st12) st12.kill(true);

			let st23 = ScrollTrigger.getById(`st2_3`)
			if(st23) st23.kill(true);

			let st34 = ScrollTrigger.getById(`st3_4`)
			if(st34) st34.kill(true);

			let st45 = ScrollTrigger.getById(`st4_5`)
			if(st45) st45.kill(true);

			let st56 = ScrollTrigger.getById(`st5_6`)
			if(st56) st56.kill(true);

			let st67 = ScrollTrigger.getById(`st6_7`)
			if(st67) st67.kill(true);

			let st78 = ScrollTrigger.getById(`st7_8`)
			if(st78) st78.kill(true);

			const ST1_2 = {
				id: `st1_2`,
				trigger: `#slide1_2`,
				start: ()=>{return `top top`},
				end: ()=>{return `bottom top`},
				markers: false,
				onEnter: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(1);
					// if(is4) setIs4(false);
				},
				onEnterBack: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(0);
					// if(is4) setIs4(false);
				},
				refreshPriority: -1,
			}

			const ST2_3 = {
				id: `st2_3`,
				trigger: `#slide2_3`,
				start: ()=>{return `top top`},
				end: ()=>{return `bottom top`},
				markers: false,
				onEnter: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(2);
					// if(is4) setIs4(false);
				},
				onEnterBack: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(1);
					// if(is4) setIs4(false);
				},
				refreshPriority: -1,
			}

			const ST3_4 = {
				id: `st3_4`,
				trigger: `#slide3_4`,
				start: ()=>{return `top top`},
				end: ()=>{return `bottom top`},
				markers: false,
				onEnter: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(3);
					// setIs4(true);
					// console.log(true)
				},
				onEnterBack: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(2);
					// setIs4(false);
					// console.log(false)
				},
				refreshPriority: -1,
			}

			const ST5_6 = {
				id: `st5_6`,
				trigger: `#slide5_6`,
				start: ()=>{return `top top`},
				end: ()=>{return `bottom top`},
				markers: false,
				onEnter: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(5);
					// if(is4) setIs4(false);
				},
				onEnterBack: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(4);
					// if(is4) setIs4(false);
				},
				refreshPriority: -1,
			}

			const ST6_7 = {
				id: `st6_7`,
				trigger: `#slide6_7`,
				start: ()=>{return `top top`},
				end: ()=>{return `bottom top`},
				markers: false,
				onEnter: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(6);
					// if(is4) setIs4(false);
				},
				onEnterBack: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(5);
					// if(is4) setIs4(false);
				},
				refreshPriority: -1,
			}

			const ST7_8 = {
				id: `st7_8`,
				trigger: `#slide7_8`,
				start: ()=>{return `top top`},
				end: ()=>{return `bottom top`},
				markers: false,
				onEnter: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(7);
					// if(is4) setIs4(false);
				},
				onEnterBack: ({progress, direction, isActive}) => {
					controlsRef.current.newSection(6);
					// if(is4) setIs4(false);
				},
				refreshPriority: -1,
			}

			gsap.timeline({scrollTrigger: ST1_2});
			gsap.timeline({scrollTrigger: ST2_3});
			gsap.timeline({scrollTrigger: ST3_4});
			gsap.timeline({scrollTrigger: ST5_6});
			gsap.timeline({scrollTrigger: ST6_7});
			gsap.timeline({scrollTrigger: ST7_8});

		}
	},[p.data.slides, mobile, checked, size])


	useEffect(() => {
		const clouds = gsap.utils.toArray(".kcloud");
		clouds.forEach(cloud => {
			let rnd = -50*(1 + Math.random());
			let rndtime = 3 + 3*Math.random()
			gsap.to(cloud, {duration: rndtime, x: rnd, repeat: -1, yoyo:true, ease: `none`});
		});

		let liftarrow = document.querySelector("#liftarrow");
		gsap.to(liftarrow, {duration: 2, y: -20, repeat: -1, yoyo:true, ease: "power2.inOut"});

		let liftbtn = document.querySelector("#liftbtn");
		let liftbtnbig = document.querySelector("#liftbtnbig");
		gsap.to(liftbtn, {duration: 1.5, opacity: 0, repeat: -1, yoyo:true, ease: "power2.inOut"});
		liftbtnbig.addEventListener("click", ()=>{
			gotoElement(`#slide1_2`)
		});

		// start lift
		let spinner = document.querySelector("#spinner");
		let allslides = document.querySelector("#allslides");
		let liftdoor = document.querySelector("#liftdoor10");
		let liftbtns = document.querySelectorAll(`.ctrlbtn`);
		let liftrealdoor0 = document.querySelector("#realdoor0");
		gsap.set(liftdoor, {scaleX: 0.01, transformOrigin: "center center"});
		gsap.set(liftrealdoor0, {scaleX: 0.01, transformOrigin: "center center"});
		let tlstart = gsap.timeline();
			tlstart.set(allslides, {opacity: 0});
			tlstart.set(liftbtns, {autoAlpha: 0 });
			tlstart.set(liftdoor, {scaleX: 0, transformOrigin: "center center"});
			tlstart.to(spinner, {autoAlpha: 0, ease: `power2.out`}, 0)
			tlstart.to(liftbtns, {autoAlpha: 1, ease: `power2.out`, stagger: 0.1}, 0)
			tlstart.to(allslides, {duration: 2, opacity: 1, ease: "power2.out"}, 0.5);
			tlstart.to(liftdoor, {duration: 1, scaleX: 1, ease: "power2.inOut"}, 1.5);
			tlstart.to(liftrealdoor0, {duration: 1, scaleX: 1, ease: "power2.inOut"}, 1.5);
	},[])

	function gotoElement(el){
		gsap.to(((isBrowser)?window:document), {
			scrollTo: {
				y: el,
				offsetY: -1,
				autoKill: false
			},
			duration: 1,
			ease:"power2.inOut"
		});
	}



	return (
		<>
			<Bg />
			<Slides data={p.data} mobile={mobile}/>
			<Controls mobile={mobile} ref={controlsRef} />
		</>
)}

export default Main



{/*
		
		<ReactScrollWheelHandler
			upHandler={(e) => {
				
					console.log("scroll up")
			}}
			downHandler={(e) => {
				
					console.log("scroll down")
			}}
		>

		</ReactScrollWheelHandler>
*/}