import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Graph51 from "../../assets/graph51.svg"

const Slide = (p) => {

	useEffect(() => {},[])
	
	return (
		<section className={s.slide + ` slide`} data-id={`5_1`}>
			<div className={s.slidecontainer} data-id={`5_1`}>
				
				<div 
					className={s.txt} 
					data-id={`5_1`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>

				<div 
					className={s.txtb + ` txt`} 
					data-id={`5_1`} 
					dangerouslySetInnerHTML={{__html: p.data[1] }} 
				/>

				<div id={`graph51`} className={s.graph51}>
					<Graph51 />
				</div>

			</div>
		</section>
)}

export default Slide