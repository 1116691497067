import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Graph6 from "../../assets/graph6.svg"

const Slide = (p) => {

	useEffect(() => {

	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`6_0`}>
			<div className={s.slidecontainer} data-id={`6_0`} id={"slidecontainer6"}>
				<div 
					className={s.txt} 
					data-id={`6_0`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>

				<div id={`graph60`} className={s.graph60}>
					<Graph6 />
				</div>

				<div id={`art6txt`}>
					<div 
						className={s.txt} 
						data-id={`6_1`} 
						dangerouslySetInnerHTML={{__html: p.data[1] }} 
					/>

					<div 
						className={s.txt} 
						data-id={`6_2`} 
						dangerouslySetInnerHTML={{__html: p.data[2] }} 
					/>
				</div>
				
			</div>

		</section>
)}

export default Slide