import React, { useEffect } from 'react'
import { gsap } from "gsap/dist/gsap";
import Tippy from '@tippyjs/react/headless';
import * as s from "./slide.module.css"
const tip = `.` + s.tippy

const Graph = (p) => {

	let wd = (p.mobile)?100:185
	let dX = (wd-2*10)/(p.data.length-1);
	let dY = wd/100
	let path = ""
	for(let i=0; i<p.data.length; i++){
		let s = (i===0)?"M":" L "
		let x = 10 + dX*i
		let y = (100 - p.data[i]) * dY
		path += (s + x + " " + y)
	}

	useEffect(() => {},[])

	function onMount() {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	    gsap.to(tip, {autoAlpha: 1, duration: 0.5, ease: `power2.out`});
	}
	function onHide({ unmount }) {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	}

	return (
		<section className={s.graph + ` graph`} data-id={p.dataid}>
			<div 
				className={s.graphname}
				dangerouslySetInnerHTML={{__html: p.city }}
			/>
			<div className={s.graphbox}>
				<div className={s.axisvalue} data-id={"0"} dangerouslySetInnerHTML={{__html: `100` }} />
				<div className={s.axisvalue} data-id={"1"} dangerouslySetInnerHTML={{__html: `0` }} />

				<svg 
					className={s.graphsvg}
					xmlns={`http://www.w3.org/2000/svg`}
					width={wd} 
					height={wd} 
					viewBox={`0 0 ${wd} ${wd}`} 
					preserveAspectRatio="none"
				>
					<defs>
						<clipPath id={`clip0_${p.dataid}`}>
							<rect x="0" y="0" width="100%" height={wd} className={s.clip + ` gr20clip`} fill="#d5eaf4"/>
						</clipPath>
					</defs>
					<g clipPath={`url(#clip0_${p.dataid})`}>
						<path 
							d={path}
							transform="translate(0 0)" 
							fill="none" 
							stroke="#FFF" 
							strokeLinecap="round" 
							strokeLinejoin="round" 
							strokeWidth="4" 
							vectorEffect="non-scaling-stroke"
						/>
					</g>
				</svg>


				<div className={s.graphpoints}>
				{
					p.data.map((d,i)=>(
						<Tippy
							key={i} 
							onMount={onMount}
							onHide={onHide}
							render={attrs => (
								<div 
									className={s.tippy}
									data-id={i} 
									{...attrs}
								>
									<span 
										className={s.tippyval}
										dangerouslySetInnerHTML={{__html: d }}
									/>
								</div>
							)}
						>
							<div 
								className={s.graphpoint}
								style={{
									top: (100 - p.data[i]) * dY - 6 + "px",
									left: 10 + dX*i - 6 + "px"
								}}
								role = "button"
								tabIndex="0"
								aria-label="button"
							/>
						</Tippy>
					))
				}
				</div>

			</div>
		
		</section>
)}

export default Graph