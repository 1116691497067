import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Sheme from "../../assets/sheme.svg"



const Slide = (p) => {

	useEffect(() => {

	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`7_0`}>
			<div className={s.slidecontainer} data-id={`7_0`} id={"slidecontainer70"}>
				<div id={`graph70`} className={s.graph70}>
					<Sheme />
				</div>

				<div 
					className={s.txt + ` txt`} 
					data-id={`7_0`} 
					dangerouslySetInnerHTML={{__html: p.data[0] }} 
				/>
			</div>
		</section>
)}

export default Slide