import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Graph61 from "../../assets/graph61.svg"

const Slide = (p) => {

	useEffect(() => {

	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`6_1`}>
			<div className={s.slidecontainer} data-id={`6_1`} id={"slidecontainer61"}>
				<div 
					className={s.txt} 
					data-id={`6_3`} 
					dangerouslySetInnerHTML={{__html: p.data[3] }} 
				/>
<div id={`graph61`} className={s.graph61}>
				<Graph61 />
</div>

				<div id={`art61txt`}>
				
				<div 
					className={s.txt} 
					data-id={`6_4`} 
					dangerouslySetInnerHTML={{__html: p.data[4] }} 
				/>
				<div 
					className={s.txt} 
					data-id={`6_5`} 
					dangerouslySetInnerHTML={{__html: p.data[5] }} 
				/>
			</div>
			</div>

		</section>
)}

export default Slide