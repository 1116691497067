import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import * as s from "./controls.module.css"

import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from 'gsap/all'

import { withpath } from '../utils/index.js'
const N = Array.from({length: 8}, (d, i) => i+1);
const isBrowser = typeof window !== "undefined"

const floors = [
	`#slide0_1`,
	`#slide1_2`,
	`#slide2_3`,
	`#slide3_4`,
	`#slide4_5`,
	`#slide5_6`,
	`#slide6_7`,
	`#slide7_8`
]

const Controls = forwardRef((p, ref) => {

	gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
	const [section, setSection] = useState(0)

	useImperativeHandle(ref, () => ({
		newSection(i) {
			setSection(i);
		}
	}));

	useEffect(() => {
	},[])

	function gotoElement(el, i){
		if(i===4){
			let tl4 = ScrollTrigger.getById(`tl4`);
			el = tl4.end
		}
		if(i===0){
			el = 0
		}
		gsap.to(((isBrowser)?window:document), {
			scrollTo: {
				y: el,
				offsetY: (i!==2 && i!==3)?-1:-5,
				autoKill: false
			},
			duration: 1,
			ease:"power2.out"
		});
	}

	return (
		<section className={s.controls}>
			<div className={s.bgdark} />
			<div 
				className={s.bgsvg} 
				style={{backgroundImage: `url(${withpath(`/img/bg.svg`)})` }}
			/>

			<div className={s.flex}>
			{
				N.map((d,i)=>(
					<div 
						className={s.btn + ((section === i)?(` ` + s.btna):``) + ` ctrlbtn`}
						data-id={i} 
						key={i}
						// role = "button"
						// tabIndex="0"
						// onKeyDown={()=>{ gotoElement(floors[i], i) }}
						onClick={() => { gotoElement(floors[i], i) }}
					>
						<span dangerouslySetInnerHTML={{__html: i+1 }} />
					</div>
				))
			}
			</div>
		</section>
)})

export default Controls