import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Svg from '../../assets/art4_0.svg';

const Slide = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`4_0`}>
			<div className={s.slidecontainer}>

				<div className={s.slidesc}>
					<div 
						className={s.txt + ` txt40`} 
						data-id={`4_0`} 
						dangerouslySetInnerHTML={{__html: p.data[0] }} 
					/>

					<div 
						className={s.txt + ` txt40`} 
						data-id={`4_1`} 
						dangerouslySetInnerHTML={{__html: p.data[1] }} 
					/>

					<div 
						className={s.txt + ` txt40`} 
						data-id={`4_2`} 
						dangerouslySetInnerHTML={{__html: p.data[2] }} 
					/>

					<div 
						className={s.txt + ` txt40`} 
						data-id={`4_3`} 
						dangerouslySetInnerHTML={{__html: p.data[3] }} 
					/>

					{
						p.data[4].map((d,i)=>(
							<div key={i} className={s.s40box + ` txt40`} data-id={i} >
								<div className={s.s40boxCity} dangerouslySetInnerHTML={{__html: d.name }} />
								<div className={s.s40boxPercent} dangerouslySetInnerHTML={{__html: d.percent }} data-id={i}/>
								<div className={s.s40boxSource} dangerouslySetInnerHTML={{__html: d.source }} data-id={i}/>
							</div>
						))
					}

				</div>
			</div>

			<div className={s.art4_0}>
				<div className={s.art} data-id={`4_0`}>
					<Svg />
				</div>
			</div>

		</section>
)}

export default Slide