import React, { useEffect } from 'react'
import * as s from "./slide.module.css"

import Svg from '../../assets/art2_1c.svg';
import Bike from '../../assets/bike.svg';

const BIKES = Array.apply(null, Array(15)).map(function (x, i) { return i; })


const Slide = (p) => {

	useEffect(() => {
	},[])

	return (
		<section className={s.slide + ` slide`} data-id={`2_1c`}>
			
			<div className={s.slidecontainer}>
			<div 
				className={s.txt} 
				data-id={`2_1c0`} 
				dangerouslySetInnerHTML={{__html: p.data[0] }} 
			/>
			<div 
				className={s.txt} 
				data-id={`2_1c1`} 
				dangerouslySetInnerHTML={{__html: p.data[1] }} 
			/>

			<div className={s.bikes}>
				{
					BIKES.map((d,i)=>(
						<div key={i} className={s.bike + ` bike`}  data-id={i<10?"0":"1"}>
								<Bike />
						</div>
					))
				}
			</div>

			<div 
				className={s.txt + ' txt'} 
				data-id={`2_1c2`} 
				dangerouslySetInnerHTML={{__html: p.data[2] }} 
			/>

			<div 
				className={s.txtxl + ' txt'} 
				data-id={`2_1c3`} 
				dangerouslySetInnerHTML={{__html: p.data[3] }} 
			/>

			<div 
				className={s.txtm + ' txt'} 
				data-id={`2_1c4`} 
				dangerouslySetInnerHTML={{__html: p.data[4] }} 
			/>
			</div>
			<div className={s.art2_1c}>
			<div className={s.art}>
				<Svg />
				
			</div>
			</div>

		</section>
)}

export default Slide